class RuntimeGroups {
  constructor() {
    const runtimeButtons = document.getElementsByClassName('js-contract-runtime-button');
    const runtimeWrappers = document.getElementsByClassName('js-contract-runtime-wrapper');
    const toggleMemoryGroups = this.toggleMemoryGroups.bind(this);

    const defaultWrapper = document.querySelector('.js-contract-runtime-wrapper:not(.hidden)');
    if (defaultWrapper) {
      toggleMemoryGroups(defaultWrapper);
    }

    document.addEventListener('click', function (event) {
      // Catch runtime button clicks only
      if (!event.target.matches('.js-contract-runtime-button')) {
        return;
      }
      // Prevent window location change
      event.preventDefault();
      // Set all buttons inactive
      for (let button of runtimeButtons) {
        button.classList.remove('bg-black', 'text-white');
        button.classList.add('text-black');
      }
      // Activate selected button
      event.target.classList.remove('text-black');
      event.target.classList.add('bg-black', 'text-white');
      // Hide all wrappers
      for (let wrapper of runtimeWrappers) {
        wrapper.classList.add('hidden');
      }
      // Get selected runtime
      const runtime = event.target.dataset.contractRuntime;
      // Show selected wrapper
      const selectedWrapper = document.getElementById(`runtime-${runtime}`);
      if (selectedWrapper) {
        selectedWrapper.classList.remove('hidden');
        toggleMemoryGroups(selectedWrapper);
      }
    }, false);
  }

  toggleMemoryGroups(selectedWrapper) {
    const memoryGroupLinks = document.querySelectorAll('#memorygroups ol a.js-component-interaction');
    for (let memoryGroupLink of memoryGroupLinks) {
      const href = memoryGroupLink.href;
      const hrefTokens = href.split('#');
      if (hrefTokens[1]) {
        const anchorTarget = selectedWrapper.querySelector(`#${hrefTokens[1]}`);
        if (anchorTarget) {
          memoryGroupLink.classList.remove('hidden');
        }
        else {
          memoryGroupLink.classList.add('hidden');
        }
      }
    }
  }
}

window.RuntimeGroups = new RuntimeGroups();
