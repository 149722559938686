class Dialog {
  constructor(button) {
    this.isOpen = false;
    this.mouseInDialog = false;
    this.btn = button;
    this.dialog = button.nextElementSibling;

    this.btn.addEventListener('mouseenter', () => this.open());
    this.btn.addEventListener('mouseleave', () => this.close(1000));
    this.btn.addEventListener('click', (event) => {
      event.stopPropagation();
      this.toggle()
    });
    this.dialog.addEventListener('mouseenter', () => {
      this.mouseInDialog = true;
    });
    this.dialog.addEventListener('mouseleave', () => {
      this.mouseInDialog = false;
      this.close(500);
    });
  }

  toggle() {
    this.isOpen ? this.close() : this.open(0);
  }

  /**
   * Entering: "transition ease-out duration-100"
   * From: "transform opacity-0 scale-95"
   * To: "transform opacity-100 scale-100"
   */
  open() {
    if (this.isOpen) return;

    this.btn.setAttribute('aria-expanded', 'true');
    this.dialog.classList.add('transition', 'ease-out', 'duration-100', 'transform', 'opacity-0', 'scale-95');
    this.dialog.classList.remove('hidden');
    setTimeout(() => {
      this.dialog.classList.remove('opacity-0', 'scale-95');
      this.dialog.classList.add('transform', 'opacity-100', 'scale-100');
      this.isOpen = true;
    }, 100);
  }

  /**
   * Leaving: "transition ease-in duration-75"
   * From: "transform opacity-100 scale-100"
   * To: "transform opacity-0 scale-95"
   *
   * @param wait
   */
  close(wait = 0) {
    if (!this.isOpen || this.mouseInDialog) return;

    setTimeout(() => {
      if (this.mouseInDialog) return;

      this.dialog.classList.add('transition', 'ease-in', 'duration-75', 'transform', 'opacity-100', 'scale-100');
      setTimeout(() => {
        this.dialog.classList.remove('transform', 'opacity-100', 'scale-100');
        this.dialog.classList.add('opacity-0', 'scale-95');

        setTimeout(() => {
          this.btn.setAttribute('aria-expanded', 'false');
          this.dialog.classList.add('hidden');
          this.isOpen = false;
        }, 75);
      }, 75);
    }, wait);
  }
}

document.querySelectorAll('.js-dialog-btn').forEach(button => new Dialog(button));
