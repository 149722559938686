class Collapse {
  constructor(button) {
    this.btn = button;
    this.btnIcon = button.querySelector('svg');
    this.isOpen = button.getAttribute('aria-expanded') === 'true';

    const targetCollapse = button.getAttribute('aria-controls');
    this.collapse = document.getElementById(targetCollapse);

    this.btn.addEventListener('click', () => this.toggle());
  }

  toggle() {
    this.isOpen ? this.close() : this.open(0);
  }

  open() {
    this.collapse.classList.remove('hidden');
    this.btn.setAttribute('aria-expanded', 'true');
    this.btnIcon.classList.add('-rotate-180');
    this.isOpen = true;
  }

  close() {
    this.collapse.classList.add('hidden');
    this.btn.setAttribute('aria-expanded', 'false');
    this.btnIcon.classList.remove('-rotate-180');
    this.isOpen = false;
  }
}

document.querySelectorAll('.js-collapse-btn').forEach(button => new Collapse(button));
